@tailwind base;
@tailwind components;
@tailwind utilities;

@import './gap.scss';
@import './vue-quill.snow.css';
@import './quill.font.css';
@import './light.css';
@import './dark.css';

html,
body {
  font-size: 16px;
  font-family: 'Helvetica Neue', Helvetica, Arial, 'PingFang SC',
    'Hiragino Sans GB', 'Heiti SC', 'Microsoft YaHei', 'WenQuanYi Micro Hei',
    sans-serif;
  overflow-x: hidden;
}

.body--light .text-primary,
.body--dark .text-primary {
  color: var(--text-primary) !important;
}

.body--light .text-secondary,
.body--dark .text-secondary {
  color: var(--text-secondary) !important;
}

.body--light .text-highlight,
.body--dark .text-highlight {
  color: var(--highlight-color) !important;
}

.body--light .border-highlight,
.body--dark .border-highlight {
  border-color: var(--indicator-color) !important;
}

.body--light .q-field__control,
.body--dark .q-field__control {
  color: var(--highlight-color);
}

.body--light .q-checkbox--light .q-checkbox__inner--truthy,
.body--dark .q-checkbox--dark .q-checkbox__inner--truthy,
.q-checkbox--dark .q-checkbox__inner--indet {
  color: var(--highlight-color);
}

.hover\:text-primary:hover,
.hover\:text-primary:focus,
.hover\:text-primary:active {
  color: var(--text-primary) !important;
}

.hover\:text-highlight:hover,
.hover\:text-highlight:focus,
.hover\:text-highlight:active {
  color: var(--highlight-color) !important;
}

@media (max-width: 800px) {
  .h-bg {
    height: 200px;
  }
}

@media (min-width: 800px) and (max-width: 1024px) {
  .h-bg {
    height: 240px;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .h-bg {
    height: 280px;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .h-bg {
    height: 360px;
  }
}

@media (min-width: 1366px) and (max-width: 1600px) {
  .h-bg {
    height: 400px;
  }
}

@media (min-width: 1600px) {
  .h-bg {
    height: 460px;
  }
}

.ql-editor {
  height: auto;
}

// .ql-editor p {
//   text-indent: 2rem;
// }

// .ql-editor p.ql-indent-1,
// .ql-editor p.ql-indent-2,
// .ql-editor p.ql-indent-3,
// .ql-editor p.ql-indent-4,
// .ql-editor p.ql-indent-5,
// .ql-editor p.ql-indent-6,
// .ql-editor p.ql-indent-7,
// .ql-editor p.ql-indent-8,
// .ql-editor p.ql-align-center,
// .ql-editor p.ql-align-right {
//   text-indent: 0;
// }
