.-gap-x-0 > * {
  margin-left: 0;
  margin-right: 0;
}
.-gap-x-0 > *:first-child {
  margin-left: 0;
}
.-gap-x-0 > *:last-child {
  margin-right: 0;
}
.-gap-y-0 > * {
  margin-top: 0;
  margin-bottom: 0;
}
.-gap-y-0 > *:first-child {
  margin-top: 0;
}
.-gap-y-0 > *:last-child {
  margin-bottom: 0;
}
.-gap-0 > * {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.-gap-x-1 > * {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}
.-gap-x-1 > *:first-child {
  margin-left: 0;
  margin-right: 0.25rem;
}
.-gap-x-1 > *:last-child {
  margin-left: 0.25rem;
  margin-right: 0;
}
.-gap-y-1 > * {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
.-gap-y-1 > *:first-child {
  margin-top: 0;
  margin-bottom: 0.25rem;
}
.-gap-y-1 > *:last-child {
  margin-top: 0.25rem;
  margin-bottom: 0;
}
.-gap-1 > * {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
.-gap-x-2 > * {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.-gap-x-2 > *:first-child {
  margin-left: 0;
  margin-right: 0.5rem;
}
.-gap-x-2 > *:last-child {
  margin-left: 0.5rem;
  margin-right: 0;
}
.-gap-y-2 > * {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.-gap-y-2 > *:first-child {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.-gap-y-2 > *:last-child {
  margin-top: 0.5rem;
  margin-bottom: 0;
}
.-gap-2 > * {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.-gap-x-3 > * {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}
.-gap-x-3 > *:first-child {
  margin-left: 0;
  margin-right: 0.75rem;
}
.-gap-x-3 > *:last-child {
  margin-left: 0.75rem;
  margin-right: 0;
}
.-gap-y-3 > * {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}
.-gap-y-3 > *:first-child {
  margin-top: 0;
  margin-bottom: 0.75rem;
}
.-gap-y-3 > *:last-child {
  margin-top: 0.75rem;
  margin-bottom: 0;
}
.-gap-3 > * {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}
.-gap-x-4 > * {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.-gap-x-4 > *:first-child {
  margin-left: 0;
  margin-right: 1rem;
}
.-gap-x-4 > *:last-child {
  margin-left: 1rem;
  margin-right: 0;
}
.-gap-y-4 > * {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.-gap-y-4 > *:first-child {
  margin-top: 0;
  margin-bottom: 1rem;
}
.-gap-y-4 > *:last-child {
  margin-top: 1rem;
  margin-bottom: 0;
}
.-gap-4 > * {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.-gap-x-5 > * {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
.-gap-x-5 > *:first-child {
  margin-left: 0;
  margin-right: 1.25rem;
}
.-gap-x-5 > *:last-child {
  margin-left: 1.25rem;
  margin-right: 0;
}
.-gap-y-5 > * {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.-gap-y-5 > *:first-child {
  margin-top: 0;
  margin-bottom: 1.25rem;
}
.-gap-y-5 > *:last-child {
  margin-top: 1.25rem;
  margin-bottom: 0;
}
.-gap-5 > * {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.-gap-x-6 > * {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.-gap-x-6 > *:first-child {
  margin-left: 0;
  margin-right: 1.5rem;
}
.-gap-x-6 > *:last-child {
  margin-left: 1.5rem;
  margin-right: 0;
}
.-gap-y-6 > * {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.-gap-y-6 > *:first-child {
  margin-top: 0;
  margin-bottom: 1.5rem;
}
.-gap-y-6 > *:last-child {
  margin-top: 1.5rem;
  margin-bottom: 0;
}
.-gap-6 > * {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.-gap-x-7 > * {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}
.-gap-x-7 > *:first-child {
  margin-left: 0;
  margin-right: 1.75rem;
}
.-gap-x-7 > *:last-child {
  margin-left: 1.75rem;
  margin-right: 0;
}
.-gap-y-7 > * {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}
.-gap-y-7 > *:first-child {
  margin-top: 0;
  margin-bottom: 1.75rem;
}
.-gap-y-7 > *:last-child {
  margin-top: 1.75rem;
  margin-bottom: 0;
}
.-gap-7 > * {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}
.-gap-x-8 > * {
  margin-left: 1rem;
  margin-right: 1rem;
}
.-gap-x-8 > *:first-child {
  margin-left: 0;
  margin-right: 2rem;
}
.-gap-x-8 > *:last-child {
  margin-left: 2rem;
  margin-right: 0;
}
.-gap-y-8 > * {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.-gap-y-8 > *:first-child {
  margin-top: 0;
  margin-bottom: 2rem;
}
.-gap-y-8 > *:last-child {
  margin-top: 2rem;
  margin-bottom: 0;
}
.-gap-8 > * {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.-gap-x-9 > * {
  margin-left: 1.125rem;
  margin-right: 1.125rem;
}
.-gap-x-9 > *:first-child {
  margin-left: 0;
  margin-right: 2.25rem;
}
.-gap-x-9 > *:last-child {
  margin-left: 2.25rem;
  margin-right: 0;
}
.-gap-y-9 > * {
  margin-top: 1.125rem;
  margin-bottom: 1.125rem;
}
.-gap-y-9 > *:first-child {
  margin-top: 0;
  margin-bottom: 2.25rem;
}
.-gap-y-9 > *:last-child {
  margin-top: 2.25rem;
  margin-bottom: 0;
}
.-gap-9 > * {
  margin-left: 1.125rem;
  margin-right: 1.125rem;
  margin-top: 1.125rem;
  margin-bottom: 1.125rem;
}
.-gap-x-10 > * {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.-gap-x-10 > *:first-child {
  margin-left: 0;
  margin-right: 2.5rem;
}
.-gap-x-10 > *:last-child {
  margin-left: 2.5rem;
  margin-right: 0;
}
.-gap-y-10 > * {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.-gap-y-10 > *:first-child {
  margin-top: 0;
  margin-bottom: 2.5rem;
}
.-gap-y-10 > *:last-child {
  margin-top: 2.5rem;
  margin-bottom: 0;
}
.-gap-10 > * {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.-gap-x-11 > * {
  margin-left: 1.375rem;
  margin-right: 1.375rem;
}
.-gap-x-11 > *:first-child {
  margin-left: 0;
  margin-right: 2.75rem;
}
.-gap-x-11 > *:last-child {
  margin-left: 2.75rem;
  margin-right: 0;
}
.-gap-y-11 > * {
  margin-top: 1.375rem;
  margin-bottom: 1.375rem;
}
.-gap-y-11 > *:first-child {
  margin-top: 0;
  margin-bottom: 2.75rem;
}
.-gap-y-11 > *:last-child {
  margin-top: 2.75rem;
  margin-bottom: 0;
}
.-gap-11 > * {
  margin-left: 1.375rem;
  margin-right: 1.375rem;
  margin-top: 1.375rem;
  margin-bottom: 1.375rem;
}
.-gap-x-12 > * {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.-gap-x-12 > *:first-child {
  margin-left: 0;
  margin-right: 3rem;
}
.-gap-x-12 > *:last-child {
  margin-left: 3rem;
  margin-right: 0;
}
.-gap-y-12 > * {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.-gap-y-12 > *:first-child {
  margin-top: 0;
  margin-bottom: 3rem;
}
.-gap-y-12 > *:last-child {
  margin-top: 3rem;
  margin-bottom: 0;
}
.-gap-12 > * {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.-gap-x-14 > * {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}
.-gap-x-14 > *:first-child {
  margin-left: 0;
  margin-right: 3.5rem;
}
.-gap-x-14 > *:last-child {
  margin-left: 3.5rem;
  margin-right: 0;
}
.-gap-y-14 > * {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
.-gap-y-14 > *:first-child {
  margin-top: 0;
  margin-bottom: 3.5rem;
}
.-gap-y-14 > *:last-child {
  margin-top: 3.5rem;
  margin-bottom: 0;
}
.-gap-14 > * {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
.-gap-x-16 > * {
  margin-left: 2rem;
  margin-right: 2rem;
}
.-gap-x-16 > *:first-child {
  margin-left: 0;
  margin-right: 4rem;
}
.-gap-x-16 > *:last-child {
  margin-left: 4rem;
  margin-right: 0;
}
.-gap-y-16 > * {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.-gap-y-16 > *:first-child {
  margin-top: 0;
  margin-bottom: 4rem;
}
.-gap-y-16 > *:last-child {
  margin-top: 4rem;
  margin-bottom: 0;
}
.-gap-16 > * {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.-gap-x-18 > * {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}
.-gap-x-18 > *:first-child {
  margin-left: 0;
  margin-right: 4.5rem;
}
.-gap-x-18 > *:last-child {
  margin-left: 4.5rem;
  margin-right: 0;
}
.-gap-y-18 > * {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}
.-gap-y-18 > *:first-child {
  margin-top: 0;
  margin-bottom: 4.5rem;
}
.-gap-y-18 > *:last-child {
  margin-top: 4.5rem;
  margin-bottom: 0;
}
.-gap-18 > * {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}
.-gap-x-20 > * {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.-gap-x-20 > *:first-child {
  margin-left: 0;
  margin-right: 5rem;
}
.-gap-x-20 > *:last-child {
  margin-left: 5rem;
  margin-right: 0;
}
.-gap-y-20 > * {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.-gap-y-20 > *:first-child {
  margin-top: 0;
  margin-bottom: 5rem;
}
.-gap-y-20 > *:last-child {
  margin-top: 5rem;
  margin-bottom: 0;
}
.-gap-20 > * {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.-gap-x-24 > * {
  margin-left: 3rem;
  margin-right: 3rem;
}
.-gap-x-24 > *:first-child {
  margin-left: 0;
  margin-right: 6rem;
}
.-gap-x-24 > *:last-child {
  margin-left: 6rem;
  margin-right: 0;
}
.-gap-y-24 > * {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.-gap-y-24 > *:first-child {
  margin-top: 0;
  margin-bottom: 6rem;
}
.-gap-y-24 > *:last-child {
  margin-top: 6rem;
  margin-bottom: 0;
}
.-gap-24 > * {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.-gap-x-28 > * {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}
.-gap-x-28 > *:first-child {
  margin-left: 0;
  margin-right: 7rem;
}
.-gap-x-28 > *:last-child {
  margin-left: 7rem;
  margin-right: 0;
}
.-gap-y-28 > * {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.-gap-y-28 > *:first-child {
  margin-top: 0;
  margin-bottom: 7rem;
}
.-gap-y-28 > *:last-child {
  margin-top: 7rem;
  margin-bottom: 0;
}
.-gap-28 > * {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.-gap-x-32 > * {
  margin-left: 4rem;
  margin-right: 4rem;
}
.-gap-x-32 > *:first-child {
  margin-left: 0;
  margin-right: 8rem;
}
.-gap-x-32 > *:last-child {
  margin-left: 8rem;
  margin-right: 0;
}
.-gap-y-32 > * {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.-gap-y-32 > *:first-child {
  margin-top: 0;
  margin-bottom: 8rem;
}
.-gap-y-32 > *:last-child {
  margin-top: 8rem;
  margin-bottom: 0;
}
.-gap-32 > * {
  margin-left: 4rem;
  margin-right: 4rem;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.-gap-x-36 > * {
  margin-left: 4.5rem;
  margin-right: 4.5rem;
}
.-gap-x-36 > *:first-child {
  margin-left: 0;
  margin-right: 9rem;
}
.-gap-x-36 > *:last-child {
  margin-left: 9rem;
  margin-right: 0;
}
.-gap-y-36 > * {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}
.-gap-y-36 > *:first-child {
  margin-top: 0;
  margin-bottom: 9rem;
}
.-gap-y-36 > *:last-child {
  margin-top: 9rem;
  margin-bottom: 0;
}
.-gap-36 > * {
  margin-left: 4.5rem;
  margin-right: 4.5rem;
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}
.-gap-x-40 > * {
  margin-left: 5rem;
  margin-right: 5rem;
}
.-gap-x-40 > *:first-child {
  margin-left: 0;
  margin-right: 10rem;
}
.-gap-x-40 > *:last-child {
  margin-left: 10rem;
  margin-right: 0;
}
.-gap-y-40 > * {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.-gap-y-40 > *:first-child {
  margin-top: 0;
  margin-bottom: 10rem;
}
.-gap-y-40 > *:last-child {
  margin-top: 10rem;
  margin-bottom: 0;
}
.-gap-40 > * {
  margin-left: 5rem;
  margin-right: 5rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.-gap-x-44 > * {
  margin-left: 5.5rem;
  margin-right: 5.5rem;
}
.-gap-x-44 > *:first-child {
  margin-left: 0;
  margin-right: 11rem;
}
.-gap-x-44 > *:last-child {
  margin-left: 11rem;
  margin-right: 0;
}
.-gap-y-44 > * {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}
.-gap-y-44 > *:first-child {
  margin-top: 0;
  margin-bottom: 11rem;
}
.-gap-y-44 > *:last-child {
  margin-top: 11rem;
  margin-bottom: 0;
}
.-gap-44 > * {
  margin-left: 5.5rem;
  margin-right: 5.5rem;
  margin-top: 5.5rem;
  margin-bottom: 5.5rem;
}
.-gap-x-48 > * {
  margin-left: 6rem;
  margin-right: 6rem;
}
.-gap-x-48 > *:first-child {
  margin-left: 0;
  margin-right: 12rem;
}
.-gap-x-48 > *:last-child {
  margin-left: 12rem;
  margin-right: 0;
}
.-gap-y-48 > * {
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.-gap-y-48 > *:first-child {
  margin-top: 0;
  margin-bottom: 12rem;
}
.-gap-y-48 > *:last-child {
  margin-top: 12rem;
  margin-bottom: 0;
}
.-gap-48 > * {
  margin-left: 6rem;
  margin-right: 6rem;
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.-gap-x-52 > * {
  margin-left: 6.5rem;
  margin-right: 6.5rem;
}
.-gap-x-52 > *:first-child {
  margin-left: 0;
  margin-right: 13rem;
}
.-gap-x-52 > *:last-child {
  margin-left: 13rem;
  margin-right: 0;
}
.-gap-y-52 > * {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
}
.-gap-y-52 > *:first-child {
  margin-top: 0;
  margin-bottom: 13rem;
}
.-gap-y-52 > *:last-child {
  margin-top: 13rem;
  margin-bottom: 0;
}
.-gap-52 > * {
  margin-left: 6.5rem;
  margin-right: 6.5rem;
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
}
.-gap-x-56 > * {
  margin-left: 7rem;
  margin-right: 7rem;
}
.-gap-x-56 > *:first-child {
  margin-left: 0;
  margin-right: 14rem;
}
.-gap-x-56 > *:last-child {
  margin-left: 14rem;
  margin-right: 0;
}
.-gap-y-56 > * {
  margin-top: 7rem;
  margin-bottom: 7rem;
}
.-gap-y-56 > *:first-child {
  margin-top: 0;
  margin-bottom: 14rem;
}
.-gap-y-56 > *:last-child {
  margin-top: 14rem;
  margin-bottom: 0;
}
.-gap-56 > * {
  margin-left: 7rem;
  margin-right: 7rem;
  margin-top: 7rem;
  margin-bottom: 7rem;
}
.-gap-x-60 > * {
  margin-left: 7.5rem;
  margin-right: 7.5rem;
}
.-gap-x-60 > *:first-child {
  margin-left: 0;
  margin-right: 15rem;
}
.-gap-x-60 > *:last-child {
  margin-left: 15rem;
  margin-right: 0;
}
.-gap-y-60 > * {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}
.-gap-y-60 > *:first-child {
  margin-top: 0;
  margin-bottom: 15rem;
}
.-gap-y-60 > *:last-child {
  margin-top: 15rem;
  margin-bottom: 0;
}
.-gap-60 > * {
  margin-left: 7.5rem;
  margin-right: 7.5rem;
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}
.-gap-x-64 > * {
  margin-left: 8rem;
  margin-right: 8rem;
}
.-gap-x-64 > *:first-child {
  margin-left: 0;
  margin-right: 16rem;
}
.-gap-x-64 > *:last-child {
  margin-left: 16rem;
  margin-right: 0;
}
.-gap-y-64 > * {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.-gap-y-64 > *:first-child {
  margin-top: 0;
  margin-bottom: 16rem;
}
.-gap-y-64 > *:last-child {
  margin-top: 16rem;
  margin-bottom: 0;
}
.-gap-64 > * {
  margin-left: 8rem;
  margin-right: 8rem;
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.-gap-x-72 > * {
  margin-left: 9rem;
  margin-right: 9rem;
}
.-gap-x-72 > *:first-child {
  margin-left: 0;
  margin-right: 18rem;
}
.-gap-x-72 > *:last-child {
  margin-left: 18rem;
  margin-right: 0;
}
.-gap-y-72 > * {
  margin-top: 9rem;
  margin-bottom: 9rem;
}
.-gap-y-72 > *:first-child {
  margin-top: 0;
  margin-bottom: 18rem;
}
.-gap-y-72 > *:last-child {
  margin-top: 18rem;
  margin-bottom: 0;
}
.-gap-72 > * {
  margin-left: 9rem;
  margin-right: 9rem;
  margin-top: 9rem;
  margin-bottom: 9rem;
}
.-gap-x-80 > * {
  margin-left: 10rem;
  margin-right: 10rem;
}
.-gap-x-80 > *:first-child {
  margin-left: 0;
  margin-right: 20rem;
}
.-gap-x-80 > *:last-child {
  margin-left: 20rem;
  margin-right: 0;
}
.-gap-y-80 > * {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.-gap-y-80 > *:first-child {
  margin-top: 0;
  margin-bottom: 20rem;
}
.-gap-y-80 > *:last-child {
  margin-top: 20rem;
  margin-bottom: 0;
}
.-gap-80 > * {
  margin-left: 10rem;
  margin-right: 10rem;
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.-gap-x-96 > * {
  margin-left: 12rem;
  margin-right: 12rem;
}
.-gap-x-96 > *:first-child {
  margin-left: 0;
  margin-right: 24rem;
}
.-gap-x-96 > *:last-child {
  margin-left: 24rem;
  margin-right: 0;
}
.-gap-y-96 > * {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
.-gap-y-96 > *:first-child {
  margin-top: 0;
  margin-bottom: 24rem;
}
.-gap-y-96 > *:last-child {
  margin-top: 24rem;
  margin-bottom: 0;
}
.-gap-96 > * {
  margin-left: 12rem;
  margin-right: 12rem;
  margin-top: 12rem;
  margin-bottom: 12rem;
}
.-gap-x-0\.5 > * {
  margin-left: 0.0625rem;
  margin-right: 0.0625rem;
}
.-gap-x-0\.5 > *:first-child {
  margin-left: 0;
  margin-right: 0.125rem;
}
.-gap-x-0\.5 > *:last-child {
  margin-left: 0.125rem;
  margin-right: 0;
}
.-gap-y-0\.5 > * {
  margin-top: 0.0625rem;
  margin-bottom: 0.0625rem;
}
.-gap-y-0\.5 > *:first-child {
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.-gap-y-0\.5 > *:last-child {
  margin-top: 0.125rem;
  margin-bottom: 0;
}
.-gap-0\.5 > * {
  margin-left: 0.0625rem;
  margin-right: 0.0625rem;
  margin-top: 0.0625rem;
  margin-bottom: 0.0625rem;
}
.-gap-x-1\.5 > * {
  margin-left: 0.1875rem;
  margin-right: 0.1875rem;
}
.-gap-x-1\.5 > *:first-child {
  margin-left: 0;
  margin-right: 0.375rem;
}
.-gap-x-1\.5 > *:last-child {
  margin-left: 0.375rem;
  margin-right: 0;
}
.-gap-y-1\.5 > * {
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem;
}
.-gap-y-1\.5 > *:first-child {
  margin-top: 0;
  margin-bottom: 0.375rem;
}
.-gap-y-1\.5 > *:last-child {
  margin-top: 0.375rem;
  margin-bottom: 0;
}
.-gap-1\.5 > * {
  margin-left: 0.1875rem;
  margin-right: 0.1875rem;
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem;
}
.-gap-x-2\.5 > * {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}
.-gap-x-2\.5 > *:first-child {
  margin-left: 0;
  margin-right: 0.625rem;
}
.-gap-x-2\.5 > *:last-child {
  margin-left: 0.625rem;
  margin-right: 0;
}
.-gap-y-2\.5 > * {
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}
.-gap-y-2\.5 > *:first-child {
  margin-top: 0;
  margin-bottom: 0.625rem;
}
.-gap-y-2\.5 > *:last-child {
  margin-top: 0.625rem;
  margin-bottom: 0;
}
.-gap-2\.5 > * {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}
.-gap-x-3\.5 > * {
  margin-left: 0.4375rem;
  margin-right: 0.4375rem;
}
.-gap-x-3\.5 > *:first-child {
  margin-left: 0;
  margin-right: 0.875rem;
}
.-gap-x-3\.5 > *:last-child {
  margin-left: 0.875rem;
  margin-right: 0;
}
.-gap-y-3\.5 > * {
  margin-top: 0.4375rem;
  margin-bottom: 0.4375rem;
}
.-gap-y-3\.5 > *:first-child {
  margin-top: 0;
  margin-bottom: 0.875rem;
}
.-gap-y-3\.5 > *:last-child {
  margin-top: 0.875rem;
  margin-bottom: 0;
}
.-gap-3\.5 > * {
  margin-left: 0.4375rem;
  margin-right: 0.4375rem;
  margin-top: 0.4375rem;
  margin-bottom: 0.4375rem;
}
