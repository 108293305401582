.body--dark {
  --color1: #42a6d7;
  --color2: #0f357c;
  --color3: #eac947;
  --color4: #62b9b9;
  --bg-color: #020618;
  --bg-secondary: #1b2d48;
  --border-color: #6b7280;
  --highlight-color: #42a6d7;
  --loading-color: #42a6d7;
  --indicator-color: #eac947;
  --text-primary: #fff;
  --text-secondary: #6b7280;
  --linear-color1: linear-gradient(to right, var(--color2), var(--color4));
  --linear-color2: linear-gradient(270deg, #39bbba, #00a9dc);
  --box-shadow: none;
}
