.body--light {
  --color1: #16a34a;
  --color2: #1f2937;
  /* --color3: #eac947;
  --color4: #62b9b9; */
  --bg-color: #fff;
  --bg-secondary: #fff;
  /* --border-color: #6b7280; */
  --highlight-color: #16a34a;
  /* --loading-color: #42a6d7; */
  --indicator-color: #16a34a;
  --text-primary: #000;
  --text-secondary: #6b7280;
  --linear-color1: linear-gradient(to right, var(--color2), var(--color4));
  --linear-color2: linear-gradient(270deg, #2fc725, #009de9);
  --box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12);
}
